@use "src/styles/breakpoints";
@use "src/styles/theme";

.outerContainer {
  position: relative;
}

$fallbackColor: #22a5ad;

.backgroundImage {
  position: absolute;
  z-index: -1;
  inset: 0;
  background-color: $fallbackColor;
  background-image: url("/images/background.jpg");
  background-size: cover;

  @include theme.dark {
    filter: saturate(0.1) brightness(0.3) contrast(1.1);
  }

  @include theme.light {
    filter: none;
  }

  @include theme.sepia {
    filter: sepia(1);
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-inline: var(--spacing-small);
  padding-top: 30px;

  @include breakpoints.tablet {
    max-width: calc(25 * var(--spacing-mega));
  }

  margin-inline: auto;
}

.imageContainer {
  height: calc(5 * var(--spacing-mega));
  width: 100%;
  max-width: calc(8 * var(--spacing-mega));
  margin-block-end: var(--spacing-medium);
  margin-block-start: var(--spacing-mega);

  @include breakpoints.tablet {
    margin-block-end: var(--spacing-mega);
    margin-block-start: var(--spacing-xxsmall);
  }

  & > svg {
    fill: var(--color-text-inverse);
  }
}

.quickLinksContainer {
  margin-block-start: var(--spacing-xxsmall);
}
